//@ts-nocheck
// Toggle preceding from @ts-nocheck to @ts-check to enable TypeScript checking

import { consoleMsg } from './utility.js';
import { takePhoto } from './index.js';
import { generateInspectionPDF } from './pdf.js';
import { isLocalStorageAvailable, deletePhoto } from './storage.js';
import { setMenu, updateOfflineStatus } from './utility.js';
import {
  next,
  previous,
  validatePage,
  restoreFromLocalStorage,
  showDiv,
  hideDiv,
  showTextArea,
} from './navigation.js';
import {
  restoreDamageMarkers,
  mapClicked,
  resetDialogState,
  storeDamageMarkers,
  removeMarker,
} from './damage-map.js';

/* Importing SignaturePad from node_modules, and required functions from signature-functions.js
(previously app.js)
*/
import SignaturePad from 'signature_pad';
import { resizeCanvas, download, dataURLToBlob } from './signature-functions.js';

/*
import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator, signInWithEmailAndPassword } from "firebase/auth";

const firebaseConfig = {
	apiKey: "AIzaSyAmEyS6veiQM8_r4KM74wBCxqN22Zw1eCw",
	authDomain: "pageone-inspection-5e889.firebaseapp.com",
	projectId: "pageone-inspection-5e889",
	storageBucket: "pageone-inspection-5e889.firebasestorage.app",
	messagingSenderId: "207677706844",
	appId: "1:207677706844:web:03a1c3cfebefb2d2f00c99",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
//connectAuthEmulator(auth, "http://localhost:9099");

//await signInWithEmailAndPassword(auth, email, password);

*/

//setInterval(updateOfflineStatus, 30000);
setInterval(updateOfflineStatus, 5000);

// Call updateOfflineStatus immediately on page load
updateOfflineStatus();

// Define the common function for initializing page classes (starting from page2)
async function initializePage(pageNo) {
  // Check on each page load if the app is offline and update the status
  const pageId = 'page' + pageNo;
  const formId = 'form' + pageNo;
  const alertId = 'alert' + pageNo;
  const pageRadioGroups = document.querySelectorAll('ion-radio-group');
  app.backButton = document.getElementById('app.backButton' + pageNo);
  app.nextButton = document.getElementById('pushButton' + pageNo);
  consoleMsg(`${pageId} entered`);
  app.form = document.getElementById(formId);
  app.currentPage = pageId;
  restoreFromLocalStorage();

  await setMenu();

  if (app.appOptions.checkRadios) {
    selectRadios(pageRadioGroups);
  }

  // Definition of text / photo reset alert
  const photoAlert = document.getElementById(alertId);
  if (photoAlert) {
    photoAlert.buttons = [
      {
        text: 'Cancel',
        role: 'cancel',
        handler: () => {
          // Define varible for passed alert data
          const alertData = alert.data.textId;
          consoleMsg('Alert data = ', alertData);
          // Derive other constants via string manipulation of passed data
          const radioGroupId = alertData.slice(0, -5) + '-group';
          const radioGroup = document.getElementById(radioGroupId);
          if (radioGroup) {
            // Reset radio group value to 'No' and dispatch ionChange event to update UI
            radioGroup.value = 'no';
            radioGroup.dispatchEvent(
              new CustomEvent('ionChange', {
                bubbles: true,
              })
            );
          }
        },
      },
      {
        text: 'OK',
        role: 'confirm',
        handler: () => {
          // Define constants for passed alert data
          const textId = photoAlert.data.textId;
          const textArea = photoAlert.data.textArea;
          const divId = photoAlert.data.divId;
          const photos = document.querySelectorAll('#' + divId + ' img');
          const photoRow = document.querySelector('#' + divId + ' ion-row');
          if (photoRow) {
            const photoRowId = photoRow.id;
          }
          consoleMsg('Alert confirmed');
          // Reset text area value and hide parent div
          textArea.value = '';
          // If there are any photos in the current row, remove from DOM and storage
          if (photos.length > 0) {
            for (let i = 0; i < photos.length; i++) {
              photos[i].remove();
            }
            store.forEach((value, key) => {
              if (key.includes(photoRowId)) {
                store.remove(key);
              }
            });
          }
          hideDiv(divId);
        },
      },
    ];
  }

  // Add event listeners for each page radio group
  pageRadioGroups.forEach((radioGroup) => {
    radioGroup.addEventListener('ionChange', showTextArea);
  });

  // Remove existing event listeners for back and next buttons
  app.backButton.removeEventListener('click', previous);
  app.nextButton.removeEventListener('click', validatePage);
  setTimeout(async () => {
    await updateOfflineStatus();
  }, 100);
}

export class Login extends HTMLElement {
  connectedCallback() {
    this.innerHTML = app.loginContent;
    this.addEventListener('ionViewDidEnter', async () => {
      setTimeout(async () => {
        await updateOfflineStatus();
      }, 100);
      consoleMsg('Login page entered');
      const loginButton = document.getElementById('login');
      const user = document.getElementById('username');
      const password = document.getElementById('password');

      app.currentPage = 'login';
      consoleMsg('Current page = ' + app.currentPage);
      setMenu('login');

      /* MD NOTE: Disabling firesync.login
			const loginEmailPassword = async () => {
				const loginEmail = user.value;
				const loginPassword = password.value;

				try {
					const userCredential = await signInWithEmailAndPassword(auth, loginEmail, loginPassword);
					consoleMsg(userCredential.user);
					consoleMsg("VALID USER");
					next("/page-one");
				} catch (error) {
					consoleMsg(error);
					consoleMsg("INVALID USER");
				}

				const userCredential = await signInWithEmailAndPassword(auth, loginEmail, loginPassword);
				consoleMsg(userCredential.user);
			};*/

      //loginButton.addEventListener("click", loginEmailPassword);
      loginButton.addEventListener('click', () => next('/page-one'));
    });
  }
}

export class PageOne extends HTMLElement {
  connectedCallback() {
    consoleMsg('Custom element pageOne connected');
    this.innerHTML = app.pageOneContent;
    this.addEventListener('ionViewDidEnter', async () => {
      setTimeout(async () => {
        await updateOfflineStatus();
      }, 100);
      await setMenu();
      consoleMsg('Page 1 entered');
      app.currentPage = 'page1';
      document.addEventListener('click', ({ target }) => {
        if (target.matches('#pushButton')) {
          consoleMsg('#pushButton event listener added...');
          next('/page-two');
        }
      });
      // Call function from main.js to populate the menu / header
      //setMenu();
    });
  }
  disconnectedCallback() {
    consoleMsg('Custom element pageOne disconnected');
  }
}

export class PageTwo extends HTMLElement {
  connectedCallback() {
    consoleMsg('Custom element pageTwo connected');
    this.innerHTML = app.pageTwoContent;
    this.addEventListener('ionViewDidEnter', async () => {
      await initializePage(2);

      const alert1 = document.getElementById('alert1');
      const storage = isLocalStorageAvailable();

      app.nextButton.addEventListener('click', () => validatePage('/page-three', '-2'));
      app.backButton.addEventListener('click', () => storeDamageMarkers(damageMarkers));
      app.backButton.addEventListener('click', () => previous('/page-one'));

      restoreDamageMarkers();

      // Map functionality wasn't working when navigating back to page, so moving event listener bindings to here

      // Custom alert settings
      alert1.inputs.id = 'select-damage-type';
      alert1.inputs = [
        {
          label: 'Scrape',
          type: 'radio',
          value: 'scrape',
          checked: true,
        },
        {
          label: 'Scratch',
          type: 'radio',
          value: 'scratch',
        },
        {
          label: 'Dent',
          type: 'radio',
          value: 'dent',
        },
        {
          label: 'Broken',
          type: 'radio',
          value: 'broken',
        },
        {
          label: 'Missing',
          type: 'radio',
          value: 'missing',
        },
      ];
      alert1.inputs.value = 'scrape';

      alert1.buttons = [
        {
          text: 'Cancel',
          role: 'cancel',
          id: 'damage-cancel',
          handler: () => {
            consoleMsg('Alert canceled');
            alert1.dismiss();
            resetDialogState();
          },
        },
        {
          text: 'OK',
          role: 'confirm',
          id: 'damage-ok',
          // Pass the undocumented alertData parameter to the handler function
          handler: (alertData) => {
            app.alert1Data = alertData;
            consoleMsg('Selected value: ' + alertData);
            // ionAlertDidDismiss event listener wasn't working without adding the below timeout
            setTimeout(() => {
              alert1.dismiss();
            }, 100);
            return { confirm: true };
          },
        },
      ];

      alert1.addEventListener('ionAlertDidDismiss', (event) => {
        consoleMsg(`Dismissed with role: ${event.detail.role}`);
      });

      const myMap = document.getElementById('myMap');
      const removeMarkerButton = document.getElementById('removeMarker');

      myMap.addEventListener('click', (event) => {
        mapClicked(event);
      });

      removeMarkerButton.addEventListener('click', (event) => {
        removeMarker();
      });

      /* Photo handling functions */

      // Function to handle the click event
      async function handlePhotoClick(event) {
        const buttonId = event.target.id;
        const rowId = buttonId.replace('takePhotoButton', 'photoRow');
        const rowElement = document.getElementById(rowId);
        const deleteButton = 'deletePhotoButton' + this.id.substr(-1);

        if (rowElement) {
          consoleMsg('Button clicked...');
          const imageUrl = await takePhoto(rowElement);
        } else {
          console.error(`Row element not found for button ID: ${buttonId}`);
        }
      }

      // Function to attach event listeners to all buttons with IDs starting with 'takePhotoButton'
      function attachPhotoEventListeners() {
        const takeButtons = document.querySelectorAll('ion-button[id^="takePhotoButton"]');
        const deleteButtons = document.querySelectorAll('ion-button[id^="deletePhotoButton"]');
        takeButtons.forEach((button) => {
          //consoleMsg("Attaching event listener to button with ID:", button.id);
          button.addEventListener('click', handlePhotoClick);
        });
        deleteButtons.forEach((button) => {
          //consoleMsg("Attaching event listener to button with ID:", button.id);
          button.addEventListener('click', deletePhoto);
        });
      }

      /* End photo handling functions */

      attachPhotoEventListeners();
    });

    this.addEventListener('ionViewWillLeave', () => {
      // Needed to add this to quit image loading from firing when leaving page: ask Beaie why
      //app.currentPage = undefined;
      consoleMsg('Page2 view left');
    });
  }
  disconnectedCallback() {
    consoleMsg('Custom element pageTwo disconnected');
  }
}

export class PageThree extends HTMLElement {
  connectedCallback() {
    this.innerHTML = app.pageThreeContent;
    this.addEventListener('ionViewDidEnter', async () => {
      await initializePage(3);

      /*setTimeout(() => {
				testPdf("#form3");
			}, 1000);*/
      const insuranceExpAlert = document.getElementById('insurance-exp');
      const registrationDateAlert = document.getElementById('registration-date');
      const insuranceRadioYes = document.getElementById('insuranceRadioYes');
      const insuranceRadioNo = document.getElementById('insuranceRadioNo');
      const registrationRadioYes = document.getElementById('registrationRadioYes');
      const registrationRadioNo = document.getElementById('registrationRadioNo');
      app.backButton.addEventListener('click', () => previous('/page-two'));
      app.nextButton.addEventListener('click', () => validatePage('/page-four', '-3'));
      insuranceRadioYes.addEventListener('click', () => showDiv('insurance-div'));
      insuranceRadioNo.addEventListener('click', () => hideDiv('insurance-div'));
      registrationRadioYes.addEventListener('click', () => showDiv('registration-div'));
      registrationRadioNo.addEventListener('click', () => hideDiv('registration-div'));

      insuranceExpAlert.buttons = [
        {
          text: 'Cancel',
          role: 'cancel',
          id: 'insurance-exp-cancel',
          handler: () => {
            consoleMsg('Alert canceled');
          },
        },
        {
          text: 'OK',
          role: 'confirm',
          id: 'insurance-exp-ok',
          handler: () => {
            consoleMsg('Alert confirmed');
          },
        },
      ];

      registrationDateAlert.buttons = [
        {
          text: 'Cancel',
          role: 'cancel',
          id: 'registration-date-cancel',
          handler: () => {
            consoleMsg('Alert canceled');
          },
        },
        {
          text: 'OK',
          role: 'confirm',
          id: 'registration-date-ok',
          handler: () => {
            consoleMsg('Alert confirmed');
          },
        },
      ];
    });
  }
}

export class PageFour extends HTMLElement {
  connectedCallback() {
    this.innerHTML = app.pageFourContent;
    this.addEventListener('ionViewDidEnter', async () => {
      await initializePage(4);
      app.backButton.addEventListener('click', () => previous('/page-three'));
      app.nextButton.addEventListener('click', () => validatePage('/page-five', '-4'));
    });
  }
}

export class PageFive extends HTMLElement {
  connectedCallback() {
    this.innerHTML = app.pageFiveContent;
    this.addEventListener('ionViewDidEnter', async () => {
      await initializePage(5);
      app.backButton.addEventListener('click', () => previous('/page-four'));
      app.nextButton.addEventListener('click', () => validatePage('/page-six', '-5'));
    });
  }
}

export class PageSix extends HTMLElement {
  connectedCallback() {
    this.innerHTML = app.pageSixContent;
    this.addEventListener('ionViewDidEnter', async () => {
      await initializePage(6);
      app.backButton.addEventListener('click', () => previous('/page-five'));
      app.nextButton.addEventListener('click', () => validatePage('/page-seven', '-6'));
    });
  }
}

export class PageSeven extends HTMLElement {
  connectedCallback() {
    this.innerHTML = app.pageSevenContent;
    this.addEventListener('ionViewDidEnter', async () => {
      await initializePage(7);
      app.backButton.addEventListener('click', () => previous('/page-six'));
      app.nextButton.addEventListener('click', () => validatePage('/page-eight', '-7'));
    });
  }
}

export class PageEight extends HTMLElement {
  connectedCallback() {
    this.innerHTML = app.pageEightContent;
    this.addEventListener('ionViewDidEnter', async () => {
      await initializePage(8);
      app.backButton.addEventListener('click', () => previous('/page-seven'));
      app.nextButton.addEventListener('click', () => validatePage('/signature', '-8'));
    });
  }
}

export class Signature extends HTMLElement {
  connectedCallback() {
    this.innerHTML = app.signatureContent;
    this.addEventListener('ionViewDidEnter', async () => {
      setTimeout(async () => {
        await updateOfflineStatus();
      }, 100);
      app.currentPage = 'page-nine';
      consoleMsg('Signature page entered');
      const submit = document.getElementById('submitButton');
      const wrapper = document.getElementById('signature-pad');
      const clearButton = wrapper.querySelector('[data-action=clear]');
      const savePNGButton = wrapper.querySelector('[data-action=save-png]');
      const canvas = wrapper.querySelector('canvas');
      const signaturePad = new SignaturePad(canvas, {
        // It's Necessary to use an opaque color when saving image as JPEG;
        // this option can be omitted if only saving as PNG or SVG
        backgroundColor: 'rgb(255, 255, 255)',
      });
      const signatureAlert = document.getElementById('signature-alert');
      const missingDataAlert = document.getElementById('missing-data-alert');
      signatureAlert.buttons = [
        {
          text: 'OK',

          handler: () => {
            signatureAlert.dismiss();
          },
        },
      ];
      clearButton.addEventListener('click', function (event) {
        signaturePad.clear();
      });
      missingDataAlert.buttons = [
        {
          text: 'OK',
          handler: () => {
            missingDataAlert.dismiss();
          },
        },
      ];

      //const problems = ["Scratch on the left door", "Dent on the rear bumper", "Cracked windshield"];
      const details = {
        Inspector: 'John Doe',
        'Inspection Date': '2024-09-01',
        'Inspection Time': '10:00 AM',
        'Vehicle Manufacterer': 'BMW',
        Model: 'X5',
        Year: '2024',
        'VIN Number': '1HG',
        Mileage: '10000',
        License: '1ABC234',
        Color: 'Black',
        Engine: 'V6',
        Transmission: 'Automatic',
      };

      submit.addEventListener('click', async function (event) {
        //await processData();
        let data = {};
        if (signaturePad.isEmpty()) {
          signatureAlert.present();
          //alert("Please provide a signature first.");
        } else {
          if (app.validator.getPageCount() !== 7) {
            //alert("Please complete all pages before submitting.");
            missingDataAlert.present();
            return;
          } else {
            const signature = signaturePad.toDataURL();
            consoleMsg(app.validator.getPageCount());
            Object.keys(localStorage).forEach((key) => {
              const value = localStorage.getItem(key);
              data[key] = value;
              //consoleMsg(key, value);
            });
            store.set('signature', signature);
            generateInspectionPDF(details);
          }
        }
      });

      // TEMPORAY: SUBMIT FORM WITHOUT VALIDATION CHECKS

      /*
			submit.addEventListener("click", async function (event) {
				//await processData();
				let problems = {};
				if (signaturePad.isEmpty()) {
					alert("Please provide a signature first.");
				} else {
					const signature = signaturePad.toDataURL();
					consoleMsg(app.validator.getPageCount());
					store.set("signature", signature);
					//globalProblems = problems;
					generateInspectionPDF(details);
				}
			});*/

      // On mobile devices it might make more sense to listen to orientation change,
      // rather than window resize events.
      window.onresize = () => resizeCanvas(canvas, signaturePad);

      window.dispatchEvent(new Event('resize'));

      //window.addEventListener("resize", resizeCanvas(canvas, signaturePad));
      //resizeCanvas(canvas, signaturePad);

      const myBack = document.getElementById('app.backButton9');
      const mySignature = document.getElementById('signature-pad');
      myBack.removeEventListener('click', previous);
      myBack.addEventListener('click', () => previous('/page-eight'));
      setMenu();

      function reloadTest() {
        consoleMsg('WINDOW RELOADED...');
      }

      // Define the resize handler
      const resizeHandler = () => {
        console.log('Window resized!');
        // Your resize handling code here
      };

      // Attach the resize event listener
      window.addEventListener('resize', resizeHandler);
    });
    this.addEventListener('ionViewWillLeave', () => {
      consoleMsg('Signature view left');
    });
  }
}

export class AppSettings extends HTMLElement {
  connectedCallback() {
    this.innerHTML = app.appSettingsContent;
    this.addEventListener('ionViewDidEnter', () => {
      app.currentPage = 'settings';
      consoleMsg('App Settings entered');
      // Query for the toggle that is used to change between palettes
      const toggle = document.getElementById('paletteToggle');
      const ripple = document.getElementById('rippleToggle');
      const ios = document.getElementById('iosToggle');
      const clearStorageAlert = document.getElementById('clearStorageAlert');
      // Use matchMedia to check the user preference
      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

      clearStorageAlert.buttons = [
        {
          text: 'OK',
          handler: () => {
            clearStorage();
            clearStorageAlert.dismiss();
          },
        },
      ];

      // Listen for the toggle check/uncheck to toggle the dark palette
      toggle.addEventListener('ionChange', (ev) => {
        consoleMsg('Toggle checked');
        toggleDarkPalette(ev.detail.checked);
      });

      // Listen for changes to the prefers-color-scheme media query
      //prefersDark.addEventListener('change', (mediaQuery) => initializeDarkPalette(mediaQuery.matches));

      // Called by the media query to check/uncheck the toggle
      function checkToggle(shouldCheck) {
        toggle.checked = shouldCheck;
      }

      // Check/uncheck the toggle and update the palette based on isDark
      function initializeDarkPalette(isDark) {
        if (app.currentPage === 'settings') {
          const toggle = document.getElementById('paletteToggle');
          toggle.checked = isDark;
        }
        toggleDarkPalette(isDark);
      }

      // Add or remove the "ion-palette-dark" class on the html element
      function toggleDarkPalette(shouldAdd) {
        document.documentElement.classList.toggle('ion-palette-dark', shouldAdd);
      }

      initializeDarkPalette(prefersDark.matches);

      // Toggle ripple effect
      ripple.addEventListener('ionChange', (ev) => {
        let rippleState = window.Ionic.config.rippleEffect;
        if (ripple.checked) {
          rippleState = true;
        } else {
          rippleState = false;
        }
        consoleMsg(rippleState);
      });

      // Toggle iOS mode
      ios.addEventListener('ionChange', (ev) => {
        let iosState = window.Ionic.mode;
        if (ios.checked) {
          iosState = 'ios';
          consoleMsg(iosState);
        } else {
          iosState = 'md';
          consoleMsg(iosState);
        }
      });

      const toggleMode = document.getElementById('iosToggle');
      toggleMode.addEventListener('ionChange', (event) => {
        const mode = event.detail.checked ? 'ios' : 'md';
        document.documentElement.setAttribute('mode', mode);
      });

      const clearStorageButton = document.getElementById('clearStorageButton');
      clearStorageButton.removeEventListener('click', clearStorage);
      clearStorageButton.addEventListener('click', () => clearStorage());

      const reloadWindowButton = document.getElementById('reloadWindowButton');
      /*reloadWindowButton.removeEventListener("click", forceReload);
			reloadWindowButton.addEventListener("click", () => forceReload());*/

      async function clearStorage() {
        clearStorageAlert.present();
        localStorage.clear();
        await store.clear();
        app.damageMarkers = {};
      }

      const checkPageRadios = document.getElementById('checkRadiosToggle');
      checkPageRadios.addEventListener('ionChange', (event) => {
        const mode = event.detail.checked;
        if (mode) {
          app.appOptions.checkRadios = true;
        } else {
          app.appOptions.checkRadios = false;
        }
      });
    });
  }
}

export class Testing extends HTMLElement {
  connectedCallback() {
    this.innerHTML = app.testingContent;
    this.addEventListener('ionViewDidEnter', () => {
      app.currentPage = 'testing';
      consoleMsg('Testing page entered');
      let templateContent = '';
      //getText();
      loadHTML('templates/testing-template.html', templateContent)
        .then((content) => {
          templateContent = content;
        })
        .then((content) => {
          const myTemplate = document.getElementById('h1-template');
          consoleMsg(myTemplate);
          myTemplate.innerHTML = templateContent;
        })
        .then((content) => {
          setMenu('testing');
        });
      loadHTML('templates/element-details-template.html', templateContent)
        .then((content) => {
          templateContent = content;
        })
        .then((content) => {
          const myTemplate = document.getElementsById('details1');
          consoleMsg(myTemplate);
          myTemplate.innerHTML = templateContent;
        });
    });
  }
}
