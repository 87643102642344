
    import { updateImagePaths } from './js/images.js';
    app.loginContent = updateImagePaths(app.loginContent);
    app.pageOneContent = updateImagePaths(app.pageOneContent);
    app.pageTwoContent = updateImagePaths(app.pageTwoContent);
    app.pageThreeContent = updateImagePaths(app.pageThreeContent);
    app.pageFourContent = updateImagePaths(app.pageFourContent);
    app.pageFiveContent = updateImagePaths(app.pageFiveContent);
    app.pageSixContent = updateImagePaths(app.pageSixContent);
    app.pageSevenContent = updateImagePaths(app.pageSevenContent);
    app.pageEightContent = updateImagePaths(app.pageEightContent);
    app.signatureContent = updateImagePaths(app.signatureContent);
    app.appSettingsContent = updateImagePaths(app.appSettingsContent);
    app.testingContent = updateImagePaths(app.testingContent);
  