/* 
********************************************************
Data and photo creation and storage functions 
********************************************************
*/

export { isLocalStorageAvailable, savePhoto, restorePicFromDb, deletePhoto };
import { consoleMsg } from './utility';

function isLocalStorageAvailable() {
  // Example provided in API book does not work.  This is the recommended approach from
  // MDN Developer Network
  try {
    var x = '__storage_test__';
    window.localStorage.setItem(x, x);
    window.localStorage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
}

/*Dual-purpose function to save photo to IndexedDB and display in app.  If flag is set to true, it means
that the function context is to restore photos from Ionic Storage and display in app.  If flag is false, it means
that a photo has been taken and is being rendered to the current page.*/

function savePhoto(imgRow, img, flag = false) {
  consoleMsg('imgRow = ', imgRow);
  consoleMsg('Flag = ', flag);
  const i = new Image();
  // Crreate an ion-col element as a wrapper to hold the photo
  const component = document.createElement('ion-col');
  const numberOfPhotos = imgRow.children.length;
  const photoId = String(numberOfPhotos + 1);
  // Set size to 3 of available 12 columns (25% column width)
  component.setAttribute('size', '3');
  i.src = img;
  i.id = 'photo' + photoId + '-' + imgRow.id;
  imgRow.append(component);
  component.append(i);
  setTimeout(() => {
    enableDeleteButton(component);
  }, 200);
  // If flag is false, store the photo in Ionic Storage
  if (!flag) {
    storePicDb(i, imgRow);
  }
}

async function storePicDb(img, imgRow) {
  try {
    const keys = await store.keys(); // Get all keys from Ionic Storage
    const photoKeys = keys.filter((key) => key.includes('photo'));
    consoleMsg('Photo keys count: ', photoKeys.length);
    consoleMsg('Image row ID = ', imgRow.id);

    let photo = {};

    // Use it to set the index for the new image
    let index = photoKeys.length + 1;
    //consoleMsg("Index: ", index);

    // Convert img.src (URL) to Blob
    const response = await fetch(img.src);
    const blob = await response.blob();

    // Convert Blob to Base64 (await the promise here)
    const base64Data = await blobToBase64(blob);
    //const photoId = "photo" + index;
    const photoId = img.id;

    // Pass references to the photo and its aspect ratio to the photo object
    photo = { id: img.id, ratio: img.naturalHeight / img.naturalWidth, row: imgRow.id, photo: base64Data };

    // Store the Base64 encoded image and its aspect ratio
    await store.set(photoId, photo);
    consoleMsg('Image stored in IndexedDB as Base64');
  } catch (error) {
    console.error('Error storing image:', error);
  }
}

async function restorePicFromDb() {
  const keys = await store.keys(); // Get all keys from Ionic Storage
  // Set flag to true to prevent further storage of images in savePhoto function
  const flag = true;
  const photoKeys = keys.filter((key) => key.includes('photo'));

  for (const key of photoKeys) {
    const currentPhoto = await store.get(key);
    const subKey = currentPhoto.photo;
    const row = currentPhoto.row;
    //let base64Pic = await store.get(key.subKey); // Await for each key retrieval
    let base64Pic = subKey;
    const imgRow = document.getElementById(row);
    if (imgRow) {
      // Check if the image for this specific key has already been added
      const existingImages = Array.from(imgRow.children).map((child) => child.firstElementChild.src);

      if (!existingImages.includes(URL.createObjectURL(base64ToBlob(base64Pic, 'image/png')))) {
        // Only add the image if it’s not already present in the row
        // Convert Base64 to Blob
        const blob = base64ToBlob(base64Pic, 'image/png');

        // Convert the Blob back to an object URL for the image
        const objectURL = URL.createObjectURL(blob);

        // Use the objectURL to restore the image
        savePhoto(imgRow, objectURL, flag); // Passing the object URL instead of the blob
      } else {
        console.error(`Row with ID ${imgRowId} not found.`);
      }
    }
  }
}

// Function to convert Blob to Base64 prior to storage

function blobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result.split(',')[1]); // Removing the "data:*/*;base64," part
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

// Function to convert Base64 to Blob for image display

function base64ToBlob(base64, mimeType) {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: mimeType });
}

function enableDeleteButton(col) {
  const deleteButton = document.getElementById('deletePhotoButton' + col.parentElement.id.substr(-1));
  deleteButton.removeAttribute('disabled');
}

async function deletePhoto() {
  const myPhotos = document.getElementById('photoRow' + this.id.substr(-1));
  // Get all keys from the store
  const keys = await store.keys();
  let lastPhoto;
  consoleMsg(myPhotos);
  consoleMsg(myPhotos.id);
  if (myPhotos.children.length > 0) {
    lastPhoto = myPhotos.lastChild.children[0];
    // Iterate over the keys using a for...of loop
    for (const key of keys) {
      //if (key.includes("photo")) {
      if (key === lastPhoto.id) {
        const photo = await store.get(key);
        const row = photo.row;
        consoleMsg('Last child ID = ', lastPhoto.id);
        await store.remove(key);
      }
    }
    myPhotos.removeChild(myPhotos.lastChild);
  }
}

function disableDeletePhotoButton(myPhotos) {
  const deleteButton = document.getElementById('deletePhotoButton' + myPhotos.id.substr(-1));
  if (myPhotos.children.length === 0) {
    deleteButton.setAttribute('disabled', '');
  }
}
