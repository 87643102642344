
    import {
      Login,
      PageOne,
      PageTwo,
      PageThree,
      PageFour,
      PageFive,
      PageSix,
      PageSeven,
      PageEight,
      Signature,
      AppSettings,
      Testing,
    } from './js/classes.js';

    customElements.define('page-login', Login);
    customElements.define('page-one', PageOne);
    customElements.define('page-two', PageTwo);
    customElements.define('page-three', PageThree);
    customElements.define('page-four', PageFour);
    customElements.define('page-five', PageFive);
    customElements.define('page-six', PageSix);
    customElements.define('page-seven', PageSeven);
    customElements.define('page-eight', PageEight);
    customElements.define('page-nine', Signature);
    customElements.define('app-settings', AppSettings);
    customElements.define('page-testing', Testing);
  