/*
***************************************
Utility functions 
***************************************
*/

export { consoleMsg, setMenu, isVisible, resetTextValue, updateOfflineStatus };

// Define function for toggling console messages
function consoleMsg(...args) {
  if (app.appOptions.isDevelopment === true) {
    console.log(...args);
    return true;
  }
}

async function loadScript(scr) {
  let script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = scr;
  let myScript = document.querySelector(`script[src="${scr}"]`);
  if (myScript) {
    consoleMsg('Script already loaded...');
    consoleMsg('Form = ', form);
  } else {
    document.head.appendChild(script);
    consoleMsg('Loading ' + script.src);
  }
}

async function unloadScript(scr) {
  let script,
    myHead = document.head;
  script = document.querySelector('script[src=' + CSS.escape(scr) + ']');
  myHead.removeChild(script);
  consoleMsg('Removed script ', src);
}

async function setMenu(page) {
  let templateContent = '',
    myMenu = 'templates/menu-template.html';
  if ((page === 'login') | (page === 'testing')) {
    myMenu = 'templates/menu-template-short.html';
    consoleMsg(myMenu);
  }
  loadHTML(myMenu, templateContent)
    .then((content) => {
      templateContent = content;
    })
    .then((content) => {
      const myTemplate = document.getElementById('header');
      myTemplate.innerHTML = templateContent;
    });
}

function getText() {
  fetch('page1.html').then((response) => {
    consoleMsg('Fetched text:');
    consoleMsg(response);
  });
}

function isVisible(element) {
  return !!element.offsetParent;
}

// Reset text area value (AKA [formerly] alert2)

/* Function that displays alert for confirmation to reset (delete) value of associated text area.  If canceled,
 associated ion-radio should be reset.  */

function resetTextValue(dialog, textId, divId) {
  const divElement = document.getElementById(divId);
  // Build query selector string to retrieve any children img elements
  const photos = document.querySelectorAll('#' + divId + ' img');
  const textArea = document.getElementById(textId);
  if (dialog) {
    dialog.message = 'Default';
    // Set alert message based on whether text area has a value, photos have been taken, or both
    if (photos.length > 0 && textArea.value) {
      consoleMsg('photos.length = ', photos.length);
      dialog.message =
        "You previously entered a description of observed damage for this component\
       and took photos of the damage.  Changing this radio button option from 'Yes' to 'No' will delete \
       your previously entered text and photos.  Proceed with deletion?";
    } else if (photos.length > 0) {
      dialog.message =
        "You previously took photos of observed damage for this component. \
       Changing this radio button option from 'Yes' to 'No' will delete your previously taken \
       photos.  Proceed with deletion?";
    } else {
      dialog.message =
        "You previously entered a description of observed damage for this component.  \
       Changing this radio button option from 'No' to 'Yes' will delete your previously entered \
       text.  Proceed with deletion?";
    }
    dialog.present(dialog);
    consoleMsg('Alert data = ', dialog.data);
    dialog.data = { textId: textId, divId: divId, textArea: textArea };
  }
}

// Get last key stored in Ionic Storage
async function getLastKey() {
  const keys = await store.keys(); // Get all keys from storage
  if (keys.length > 0) {
    const lastKey = keys[keys.length - 1]; // Get the last key
    consoleMsg('Last key:', lastKey);
    return lastKey;
  } else {
    consoleMsg('No keys found in storage.');
    return null;
  }
}

function removeDate(ionDate, div) {
  consoleMsg('Date alert OK clicked');
  ionDate.value = undefined;
  hideDiv(div);
}

/* Function to check one checkmark from each group so don't manually need to check them.
 While this function works, it iterates through each check mark and toggles the state to 'No'.
 I'd rather check the first radio in each group and then move on to next radio button group,
 but everything I've tried hasn't worked.  Think more about this next week, but adding as a stub on each page
 for now.*/

function selectRadios(array) {
  for (each in array) {
    if (app.appOptions.checkRadios) {
      // Page 7 has different radio button values, so handle as separate case
      if (app.currentPage === 'page7') {
        array[each].value = 'ok';
      } else {
        array[each].value = 'yes';
      }
    }
  }
}

function selectFile(uploadDiv) {
  let input = document.createElement('input');
  document.getElementById('bannerImg').style.display = 'block';
  input.type = 'file';
  input.accept = '.jpeg,.jpg,.png';
  input.onchange = (_) => {
    // you can use this method to get file and perform respective operations
    let files = Array.from(input.files);
    if (files && files[0]) {
      var reader = new FileReader();

      reader.onload = function (e) {
        document.getElementById('bannerImg').src = e.target.result;
        consoleMsg('Saved image');
      };
    }

    reader.readAsDataURL(input.files[0]);
    //consoleMsg(files);
  };
  input.click();
}

function getBase64Image(img) {
  var canvas = document.createElement('canvas');
  canvas.width = img.width;
  canvas.height = img.height;

  var ctx = canvas.getContext('2d');
  ctx.drawImage(img, 0, 0);

  var dataURL = canvas.toDataURL('image/png');

  return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
}

function localStorageSpace() {
  var allStrings = '';
  for (var key in window.localStorage) {
    if (window.localStorage.hasOwnProperty(key)) {
      allStrings += window.localStorage[key];
    }
  }
  return allStrings ? 3 + (allStrings.length * 16) / (8 * 1024) + ' KB' : 'Empty (0 KB)';
}

function showDialog(id) {
  let dialog = document.getElementById(id);
  dialog.show();
}

function hideDialog(id, val) {
  consoleMsg('Dialog value = ' + val);
  document.getElementById(id).hide();
  return val;
}

/* 08/01/24: New function added to check Internet connection */
const checkOnlineStatus = async () => {
  if (!navigator.onLine) {
    return false; // Device is not connected to a network
  }
  try {
    //const online = await fetch("assets/img/1pixel.png");
    const online = await fetch('https://jsonplaceholder.typicode.com/todos/1', { cache: 'no-store' });
    return online.status >= 200 && online.status < 300; // either true or false
  } catch (err) {
    return false; // definitely offline
  }
};

const updateOfflineStatus = async () => {
  const offlineDiv = document.querySelector('.offline');
  const result = await checkOnlineStatus();
  if (offlineDiv) {
    if (result) {
      offlineDiv.classList.add('hidden');
    } else {
      offlineDiv.classList.remove('hidden');
    }
  }
};
