import { Storage } from '@ionic/storage';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';

import { savePhoto } from './storage.js';
import { consoleMsg } from './utility.js';

export { takePhoto };

//import { SplashScreen } from "@capacitor/splash-screen";

// Hide the splash (you should do this on app launch)
//await SplashScreen.hide();

// Show the splash for two seconds and then automatically hide it:
/*await SplashScreen.show({
	showDuration: 2000,
	autoHide: true,
});*/

let store;

async function initStorage() {
  store = new Storage();
  await store.create();
  consoleMsg('Ionic Storage is ready');

  // Assign store to the global window object for access in the console
  window.store = store;
}

initStorage();

const keys = await store.keys();

if (keys.length > 0 && keys.includes('damage-markers')) {
  consoleMsg('Damage markers in Ionic storage...');
  app.damageMarkers = await store.get('damage-markers');
} else {
  consoleMsg('DM NOT STORED...');
}

async function takePhoto(element) {
  consoleMsg('Camera object = ', Camera);
  const image = await Camera.getPhoto({
    //quality: 90,
    quality: 100,
    allowEditing: false,
    resultType: CameraResultType.Uri,
    source: CameraSource.Camera,
  });
  savePhoto(element, image.webPath);
  consoleMsg('Photo URL:', image.webPath);
}

// Check if the Capacitor object is available
if (Capacitor) {
  console.log('Capacitor object is available.');

  // Check if the app is running on an iOS device
  const platform = Capacitor.getPlatform();
  console.log('Capacitor.getPlatform() returned:', platform);
  const isIOS = platform === 'ios';
  console.log('Platform:', platform);
  console.log('isIOS:', isIOS);
} else {
  console.error('Capacitor object is not available.');
}

// Register the service worker
if ('serviceWorker' in navigator) {
  console.log('Browser supports service workers.');

  // Check if the app is running on an iOS device
  const platform = Capacitor.getPlatform();
  const isIOS = platform === 'ios';
  console.log('Platform:', platform);
  console.log('isIOS:', isIOS);

  if (!isIOS) {
    navigator.serviceWorker
      //.register('/service-worker.js', { scope: '/' })
      .register('/sw.js', { scope: '/' })

      .then((registration) => {
        console.log('ServiceWorker registered successfully:', registration);
        console.log('Registration scope:', registration.scope);
      })
      .catch((error) => {
        console.error('ServiceWorker registration failed:', error.message, error);
      });
  } else {
    console.log('Service workers are not supported on iOS devices.');
  }
} else {
  console.error('Service workers are not supported in this browser.');
}

if (Capacitor.isPluginAvailable('StatusBar')) {
  window.addEventListener('statusTap', function () {
    consoleMsg('statusbar tapped');
  });
}

// Function to wait for the content to be fully loaded
function waitForContent(selector) {
  return new Promise((resolve) => {
    const checkContent = () => {
      const element = document.querySelector(selector);
      if (element) {
        resolve(element);
      } else {
        requestAnimationFrame(checkContent);
      }
    };
    checkContent();
  });
}
