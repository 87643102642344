/*
********************************************************************************
Functions related to navigation and page validation
********************************************************************************
*/

export { next, previous, validatePage, restoreFromLocalStorage, showDiv, hideDiv, showTextArea };
import { generateDamageImage } from './damage-map.js';
import { restorePicFromDb } from './storage.js';
import { isVisible, resetTextValue, consoleMsg } from './utility.js';

// #region JSDoc
/**
 * Navigates to the next page using the ion-router component.
 * @param {string} page - The page to navigate to.
 */
// #endregion
function next(page) {
  const router = /** @type {any} */ (document.querySelector('ion-router'));
  if (router) {
    router.push(page);
  }
}

/**
 * Validates the current page and navigates to the next page if valid.
 * @param {string} page - The page to navigate to if validation passes.
 */
async function validatePage(page) {
  consoleMsg('validatePage function called...');
  const pageRadioGroups = document.querySelectorAll('ion-radio-group');
  const pageTextAreas = document.querySelectorAll('ion-textarea');
  const datePickers = document.querySelectorAll('ion-datetime');
  const ionRange = document.querySelectorAll('ion-range');
  const pagePhotoAreas = document.querySelectorAll('ion-row');
  const currentPage = app.currentPage;

  let firstErrorElement = null;

  const radiosValid = await checkRadioValues(pageRadioGroups);
  consoleMsg('Radios valid = ' + radiosValid);
  if (!radiosValid && !firstErrorElement) {
    firstErrorElement = findFirstInvalidElement(pageRadioGroups);
  }

  const textValid = await checkTextAreaValues(pageTextAreas);
  consoleMsg('Text areas valid = ' + textValid);
  if (!textValid) {
    firstErrorElement = findFirstInvalidElement(pageTextAreas);
    consoleMsg('First invalid text area found:', firstErrorElement);
  }

  const photosValid = await checkPhotos(pagePhotoAreas);
  consoleMsg('Photos valid = ' + photosValid);
  //if (!photosValid && !firstErrorElement) {
  if (!photosValid) {
    firstErrorElement = findFirstInvalidElement(pagePhotoAreas);
    consoleMsg('First invalid photo area found:', firstErrorElement);
  }

  if (radiosValid && textValid && photosValid) {
    consoleMsg('No errors...');
    consoleMsg('Current page = ' + app.currentPage);
    app.validator.validatePage(app.currentPage, true);
    consoleMsg('Current page validation status = ' + app.validator.getValidationStatus(currentPage));
    await handleSubmit(currentPage);
    if (currentPage === 'page2') {
      await generateDamageImage(app.damageMarkers);
      await generateDamageImage(app.damageMarkers, 'legendCanvas', 'assets/img/legend.png');
      await generateDamageImage(app.damageMarkers, 'logoCanvas', 'assets/img/logo-300.png');
    }
    next(page);
  } else {
    consoleMsg('ERRORS ON PAGE!');
    app.validator.validatePage(app.currentPage, false);
    if (firstErrorElement) {
      consoleMsg('Scrolling to first error element:', firstErrorElement);
      firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
}

function findFirstInvalidElement(elements) {
  consoleMsg('Finding first invalid element...');
  for (const element of elements) {
    if (!element.value) {
      consoleMsg('First invalid element found:', element);
      return element;
    }
  }
  return null;
}

/**
 * Handles form submission.
 */

async function handleSubmit(currentPage) {
  // Strip out the page number from currentPage string
  const page = currentPage.slice(-1);
  consoleMsg('Current page = ', page);
  consoleMsg('handleSubmit called...');

  // Ensure app.form is correctly referenced
  if (!app.form) {
    console.error('Form is not defined');
    return;
  }

  const formData = new FormData(app.form);
  // Define two objects: one for retrieving keys and data from the form, and one to save to Ionic Storage
  const data = {};
  let obj = {};
  obj[currentPage] = {};
  for (let keyValue of formData.entries()) {
    data[keyValue[0]] = keyValue[1];
    localStorage.setItem(String(keyValue[0]), String(keyValue[1]));
    const myKey = String(page + '-' + keyValue[0]);
    const myVal = String(keyValue[1]);
  }
  let index = 1;

  for (let i = 0; i < Object.keys(data).length; i++) {
    const myString = document.getElementById(Object.keys(data)[i]);
    const key = Object.keys(data)[i];
    const value = Object.values(data)[i];
    //consoleMsg("My string = ", myString);
    let strValue;
    if (myString.tagName === 'DIV') {
      strValue = myString.firstElementChild.textContent;
      obj[currentPage][index] = { id: key, value: value, string: strValue, index: index, page: page };
      index++;
      // If present, get the value of the ion-textarea
    } else if (myString.tagName === 'ION-TEXTAREA' && myString.value !== '') {
      obj[currentPage][index] = { id: key, value: value, string: '', index: index, page: page };
      index++;
      // If present, get the value of the ion-datetime
      //} else if (myString.tagName === "ION-DATETIME" && myString.value !== undefined) {
    } else if (myString.tagName === 'ION-DATETIME' && myString.value) {
      // Strip out the time from the date string
      const dateValue = value.slice(0, 10);
      consoleMsg('Date value = ', myString.value);
      obj[currentPage][index] = { id: key, value: dateValue, string: myString.id, index: index, page: page };
      index++;
      // Get value of ion-range
    } else if (myString.tagName === 'ION-RANGE') {
      obj[currentPage][index] = { id: key, value: value, string: key, index: index, page: page };
      index++;
    }
  }
  await store.set(currentPage, obj);
  consoleMsg(obj);
  consoleMsg('Data length = ', Object.keys(data).length);
}

/**
 * Navigates to the previous page using the ion-router component.
 * @param {string} page - The page to navigate to.
 */
async function previous(page) {
  // Treat Signature page differently (skip handleSubmit function)
  if (app.currentPage !== 'page-nine') {
    // Store current page form values to local storage so they can later be restored
    await handleSubmit(app.currentPage);
  }
  /** @type {any} */
  const router = document.querySelector('ion-router');
  if (router) {
    await router.push(page, 'back');
  }
}

function restoreFromLocalStorage() {
  consoleMsg('restoreFromLocalStorage called...');
  // #region JSDoc
  /**
   * @typedef {HTMLElement & { name: string, value: string }} HTMLIonRadioGroupElement
   * @typedef {HTMLElement & { value: string }} HTMLIonTextareaElement
   * @typedef {HTMLElement & { value: string }} HTMLIonDatetimeElement
   * @typedef {HTMLElement & { value: string }} HTMLIonRangeElement
   * @typedef {HTMLElement & { checked: boolean, name: string, value: string }} HTMLIonRadioElement
   */

  /**
   * Map of selectors to types for query results
   * @type {{
   *   "ion-radio-group": NodeListOf<HTMLIonRadioGroupElement>,
   *   "ion-textarea": NodeListOf<HTMLIonTextareaElement>,
   *   "ion-datetime": NodeListOf<HTMLIonDatetimeElement>,
   *   "ion-range": NodeListOf<HTMLIonRangeElement>
   * }}
   */
  // #endregion

  // Map of element types to query selectors defined in JSDoc type definition
  const elementTypeMap = {
    'ion-radio-group': document.querySelectorAll('ion-radio-group'),
    'ion-textarea': document.querySelectorAll('ion-textarea'),
    'ion-datetime': document.querySelectorAll('ion-datetime'),
    'ion-range': document.querySelectorAll('ion-range'),
  };

  const radioGroups = elementTypeMap['ion-radio-group'];
  const textAreas = elementTypeMap['ion-textarea'];
  const datePickers = elementTypeMap['ion-datetime'];
  const ionRange = elementTypeMap['ion-range'];

  if (app.currentPage === 'page2') {
    restorePicFromDb();
  }

  // Restore radio group values
  radioGroups.forEach(async (radioGroup) => {
    //const storedValue = localStorage.getItem(radioGroup.name);
    const storedValue = await localStorage.getItem(radioGroup.name);
    if (storedValue) {
      // Set the ion-radio-group value directly
      radioGroup.value = storedValue;

      // Find the ion-radio with the matching value
      /** @type {HTMLIonRadioElement | null} */
      const radioButton = radioGroup.querySelector(`ion-radio[value="${storedValue}"]`);

      if (radioButton) {
        // Ensure the ion-radio is marked as checked
        radioButton.checked = true;
        const div = radioButton.name + '-div';
        const dateTime = radioButton.name + '-date';
        const myDiv = document.getElementById(div);
        const myDate = document.getElementById(dateTime);
        if (radioButton.value === 'no') {
          // Check to make sure radio button group has an associated textarea
          if (myDiv) {
            showDiv(div);
          }
        } else if (radioButton.value === 'yes') {
          if (myDate) {
            showDiv(div);
          }
        }

        // Dispatch the ionChange event to update the UI
        radioButton.dispatchEvent(
          new CustomEvent('ionChange', {
            bubbles: true,
          })
        );
      }
    }
  });

  // Restore datePickers
  if (datePickers) {
    datePickers.forEach((dt) => {
      const storedDateTime = localStorage.getItem(dt.id);
      if (storedDateTime) {
        dt.value = storedDateTime;
      }
    });
  }

  // Restore text area values
  if (textAreas) {
    textAreas.forEach((textArea) => {
      const storedText = localStorage.getItem(textArea.id);
      if (storedText) {
        textArea.value = storedText;
      }
    });
  }

  // Restore range values
  if (ionRange) {
    ionRange.forEach((range) => {
      const storedRange = localStorage.getItem(range.id);
      if (storedRange) {
        range.value = storedRange;
      }
    });
  }
}

function showDiv(div) {
  const myDiv = document.getElementById(div);
  // Check that myDiv references an actual page element
  if (myDiv) {
    myDiv.classList.remove('hidden');
  }
}

function hideDiv(div) {
  const myDiv = document.getElementById(div);
  // Check that myDiv references an actual page element
  if (myDiv) {
    myDiv.classList.add('hidden');
  }
}

function getRadioCount(name) {
  var count = 0;
  var radios = document.getElementsByName(name);
  return radios.length;
}

/**
 * Iterates through all text areas on current page.  If visible, checks that the user
 * has actually entered text.  If not, display an error div.
 *
 * @param {array} array
 * @returns {void}
 */

function checkText(array) {
  var textInput = false;
  var errorObj = {};

  // iterate through all text areas in array
  for (var i = 0; i < array.length; i++) {
    // assign current textarea to a variable
    var ta = array[i];
    var taId = ta.parentElement.id;
    var errorDiv = ta.parentElement.id + '-error';

    // Reset each error div to hidden to clear previous check

    hideDiv(errorDiv);
    // Iterate through all visible textareas to find ones that are empty
    if (!ta.parentElement.classList.contains('hidden')) {
      consoleMsg('Parent id = ' + ta.parentElement.id);
      if (ta.value == '') {
        showDiv(errorDiv);
        errorObj[taId] = taId;
        // Check if element has been previously added to local storage and, if so, remove
        /*if(localStorage.hasOwnProperty(taId)){
                     localStorage.removeItem(taId);
                 }*/
        consoleMsg('Error object length = ' + Object.keys(errorObj).length);
      } else {
        delete errorObj[taId];
        consoleMsg('Error object length = ' + Object.keys(errorObj).length);
        /*localStorage.setItem(taId, ta.value);
                 
                 consoleMsg(taId + " val = " + ta.value);
                 consoleMsg("Error object length = " + Object.keys(errorObj).length);	*/
      }
    }
  }
  if (Object.keys(errorObj).length === 0) {
    textInput = true;
  }
  //return errorObj;
  return textInput;
}

function checkRadioValues(group) {
  let errors = 0;
  let valid;

  group.forEach((each) => {
    if (!each.value) {
      consoleMsg('No value selected for ' + each.name);
      showDiv(each.name + '-error');
      errors += 1;
    } else {
      consoleMsg('VALUE = ' + each.value);
      hideDiv(each.name + '-error');
    }
  });
  if (errors === 0) {
    return (valid = true);
  } else {
    consoleMsg('Radio errors count : ' + errors);
  }
}

function checkTextAreaValues(areas) {
  let valid;
  let errors = 0;
  areas.forEach((area) => {
    if (isVisible(area)) {
      let errorDiv = area.parentElement.id + '-error';
      if (!area.value) {
        consoleMsg(area.id + ' has no value');
        showDiv(errorDiv);
        errors += 1;
      } else {
        consoleMsg(area.id + ' = ' + area.value);
        hideDiv(errorDiv);
      }
    }
  });
  if (errors === 0) {
    valid = true;
  }
  consoleMsg('Text area error count: ' + errors);
  return valid;
}

function checkPhotos(photoRows) {
  let valid;
  let errors = 0;
  photoRows.forEach((row) => {
    if (isVisible(row)) {
      let errorDiv = row.parentElement.parentElement.id + '-photo-error';
      if (row.children.length === 0) {
        consoleMsg('No photo taken');
        consoleMsg('Error div = ', errorDiv);
        showDiv(errorDiv);
        errors += 1;
      } else {
        consoleMsg('Photo taken');
        hideDiv(errorDiv);
      }
    }
  });
  if (errors === 0) {
    valid = true;
  }
  return valid;
}

function showTextArea() {
  const alertId = 'alert' + app.currentPage.substr(-1);
  const div = this.name + '-div';
  const photos = document.querySelectorAll('#' + div + ' img');
  const text = this.name + '-text';
  const myText = document.getElementById(text);
  const dialog = document.getElementById(alertId);
  // Check if textarea exists for div
  if (myText) {
    if (this.value === 'no') {
      showDiv(div);
    } else {
      // Check if text area has a value or damage photos have been taken
      if (myText.value || photos.length > 0) {
        resetTextValue(dialog, text, div);
      } else {
        hideDiv(div);
      }
    }
  }
}
