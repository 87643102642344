// File for importing images so that they can be optimized by Vite
export { updateImagePaths };

import legendImg from '../assets/img/legend.png';
import car1000 from '../assets/img/car-1000.png';
import car2000 from '../assets/img/car-2000.png';
import logoImg186 from '../assets/img/logo-186.png';
import logoImg300 from '../assets/img/logo-300.png';
import logoImg372 from '../assets/img/logo-372.png';
import logoImg558 from '../assets/img/logo-558.png';
import logoImg600 from '../assets/img/logo-600.png';
import logoImg900 from '../assets/img/logo-900.png';
import crosshairs from '../assets/img/crosshairs-256.png';
import exclamationCircle from '../assets/img/exclamation-circle-256.png';
import exclamationTriangle from '../assets/img/exclamation-triangle-256.png';
import questionCircle from '../assets/img/question-circle-256.png';
import timesCircle from '../assets/img/times-circle-256.png';

// Override the default value of isDevelopment in the global app object
app.appOptions.isDevelopment = import.meta.env.DEV;
console.log('isDevelopment:', app.appOptions.isDevelopment);

const imgMapping = {
  'legend.png': legendImg,
  'car-1000.png': car1000,
  'car-2000.png': car2000,
  'logo-186.png': logoImg186,
  'logo-300.png': logoImg300,
  'logo-372.png': logoImg372,
  'logo-558.png': logoImg558,
  'logo-600.png': logoImg600,
  'logo-900.png': logoImg900,
  'crosshairs-256.png': crosshairs,
  'exclamation-circle-256.png': exclamationCircle,
  'exclamation-triangle-256.png': exclamationTriangle,
  'question-circle-256.png': questionCircle,
  'times-circle-256.png': timesCircle,
};

function updateImagePaths(content) {
  if (!app.appOptions.isDevelopment) {
    Object.keys(imgMapping).forEach((key) => {
      console.log('Original content:', content);
      const regex = new RegExp(`assets/img/${key}`, 'g');
      console.log(`Replacing ${regex} with ${imgMapping[key]}`);
      content = content.replace(regex, imgMapping[key]);
    });
    console.log('Updated content:', content);
  }
  return content;
}

export {
  legendImg,
  car1000,
  car2000,
  logoImg186,
  logoImg300,
  logoImg372,
  logoImg558,
  logoImg600,
  logoImg900,
  crosshairs,
  exclamationCircle,
  exclamationTriangle,
  questionCircle,
  timesCircle,
};

// Define variables for each image so they will be bundled / optimized by Vite
const myLegend = legendImg,
  myCar1000 = car1000,
  myCar2000 = car2000,
  myLogo186 = logoImg186,
  myLogo372 = logoImg372,
  myLogo558 = logoImg558,
  myLogo600 = logoImg600,
  myLogo900 = logoImg900,
  myLogo300 = logoImg300,
  myCrosshairs = crosshairs,
  myExclamationCircle = exclamationCircle,
  myExclamationTriangle = exclamationTriangle,
  myQuestionCircle = questionCircle,
  myTimesCircle = timesCircle;
